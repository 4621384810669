import React from "react";
import TransparentHeader from "../components/global/TransparentHeader";
import ContentServices from "../components/Services/ContentServices";
import BaseLayout from "../components/global/BaseLayout";

function Services() {
  return (
    <BaseLayout PageName="Servicios">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Servicios"
          Subheader="Servicios"
        />
        <ContentServices/>
      </div>
    </BaseLayout>
  );
}

export default Services;
