import React from "react";
import TransparentHeader from "../components/global/TransparentHeader";
import Map from "../components/Contact/MapContent";
import BaseLayout from "../components/global/BaseLayout";
import ContactInfo from "../components/Contact/ContactInfo_3";

function Contact() {
  return (
    <BaseLayout PageName="Contáctenos">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Contáctenos"
          Subheader="Contáctenos"
        />
        <ContactInfo />
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Contact;
