import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import GalleryComponent from "../components/Gallery/Gallery";
import BeforeAfter from "../components/Gallery/BeforeAfter";

function Gallery() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Galería">
      <div className="w-full">
        <TransparentHeader
          headertitle="Galería"
          Subheader="Galería"
        />
        {
          rpdata?.beforeAfterGall?.length > 0 ?
            <BeforeAfter />
            : null
        }
        <GalleryComponent />
        {/* <div className="w-4/5 mx-auto text-center pb-20">
          <h2 className="text-center pt-[100px]">{rpdata?.labels?.general?.titleGallery}</h2>

          <div className="elfsight-app-56b4aabd-c12b-4adb-8492-35a79650bfb4"></div>
        </div> */}
      </div>
    </BaseLayout>
  );
}

export default Gallery;
